import React from 'react';
import { motion } from 'framer-motion';
import { pageVariants } from '../../animations/animations';

function Home() {
    return (
        <main>
            <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
        >
            <div>
                <h1>Hello!</h1>
            </div>
        </motion.div>
            <motion.div
                initial="initial2"
                animate="in2"
                exit="out"
                variants={pageVariants}
            >
                <div>
                    Welcome to Niseii's Website!

                </div>
            </motion.div>
        </main>


    )
}

export default Home;